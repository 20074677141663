/* eslint-disable max-len */

import React from 'react';

const MedicationIcon = (props) => (
  <svg viewBox="0 0 13 16" {...props}>
    <path d="M9.14273959,4.5519144e-14 C10.2782445,4.5519144e-14 11.2,0.806570657 11.2,1.80018002 L11.2,2.4 L1.6,2.4 L1.6,1.80018002 C1.6,0.806570657 2.52175553,4.5519144e-14 3.65726041,4.5519144e-14 L9.14273959,4.5519144e-14 Z" />
    <path d="M11.9666773,6.78125536 L9.95584,4.89609851 L9.95584,4 L2.84416,4 L2.84416,4.89609851 L0.833322667,6.78125536 C0.318314667,7.26329456 1.45483625e-12,7.92969345 1.45483625e-12,8.66641222 L1.45483625e-12,14.6664022 C1.45483625e-12,15.403121 0.63584,15.99922 1.42250667,15.99922 L11.3774933,16 C12.162496,16 12.8,15.403121 12.8,14.6671822 L12.8,8.66639222 C12.8,7.92967345 12.481664,7.26327456 11.9666773,6.78123536 L11.9666773,6.78125536 Z M9.24411733,10.6664489 L7.110784,10.6664489 L7.110784,12.6664456 L5.68910933,12.6664456 L5.68910933,10.6664489 L3.555776,10.6664489 L3.555776,9.33285111 L5.68910933,9.33285111 L5.68910933,7.33285445 L7.110784,7.33285445 L7.110784,9.33285111 L9.24411733,9.33285111 L9.24411733,10.6664489 Z" />
  </svg>
);

MedicationIcon.defaultProps = {
  height: 13,
  width: 16,
  fill: 'black',
};

export default MedicationIcon;
