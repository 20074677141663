/* istanbul ignore file */

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { EditorContent, useEditor, BubbleMenu } from '@tiptap/react';

import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';

import { TextInput } from 'components/Form';

import { MenuBar } from './MenuBar';

import theme from './Wysiwyg.sss';

const extensions = [
  StarterKit.configure({
    codeBlock: false,
    code: false,
    bulletList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Marked by the author of the library, he needs to have this as false
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Marked by the author of the library, he needs to have this as false
    },
  }),
  Link.configure({
    autolink: true,
    defaultProtocol: 'https',
  }),
];

const SIZES = {
  xsmall: 100,
  small: 200,
  medium: 400,
  large: 600,
};

/**
 * NOTE: Adding CKEditor to jsdom for unit testing is not easy at all.
 * Instead we use the TextInput component for "test" environments
 */

const InputComponent =
  process.env.NODE_ENV === 'test'
    ? ({
        size, // eslint-disable-line no-unused-vars
        onChange,
        ...others
      }) => (
        <TextInput
          data-testid="Wysiwyg"
          {...others}
          onChange={(ev) => {
            onChange(ev.target.value);
          }}
        />
      )
    : ({ value, defaultValue, size, onChange, hideToolBar }) => {
        React.useEffect(() => {
          if (editor && value === '') {
            editor.commands.clearContent(false);
          }
        }, [value, editor]);
        const editor = useEditor({
          extensions,
          content: defaultValue,
          onUpdate: ({ editor }) => {
            const newValue = editor.getText() !== '' ? editor.getHTML() : '';
            onChange(newValue);
          },
        });
        return (
          <>
            {!hideToolBar && <MenuBar editor={editor} />}
            <EditorContent
              style={{ height: `${SIZES[size]}px` }}
              className={theme.EditorContent}
              editor={editor}
            />
          </>
        );
      };

// eslint-disable-next-line no-unused-vars
const Wysiwyg = forwardRef(({ className, error, ...others }, ref) => (
  <div className={classNames(className)}>
    <InputComponent {...others} />
    {error && <span className={theme.ErrorText}>{error}</span>}
  </div>
));

Wysiwyg.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(SIZES)),
  onChange: PropTypes.func,
  hideToolBar: PropTypes.bool,
};

Wysiwyg.defaultProps = {
  size: 'medium',
  hideToolBar: false,
};

Wysiwyg.displayName = 'Wysiwyg';

export default Wysiwyg;
