import React from 'react';
import PropTypes from 'prop-types';

import IconButton from 'components/IconButton';
import {
  BoldIcon,
  ItalicIcon,
  StrikethroughIcon,
  ParagraphIcon,
  BulletListIcon,
  OrderedListIcon,
  RotateLeftIcon,
  RotateRightIcon,
  QuoteLeftIcon,
  HeadingIcon,
  EllipsisVerticalIcon,
  LinkIcon,
  LinkSlashIcon,
} from 'components/Icon';

import { black01, turquoise01 } from 'styles/colors.sss';

import theme from './MenuBar.sss';

export function MenuBar({ editor }) {
  if (!editor) {
    return null;
  }

  const setHyperlink = React.useCallback(() => {
    const previousUrl = editor.getAttributes('link').href;
    const url = window.prompt('URL', previousUrl);

    if (url === null) {
      return;
    }

    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run();
      return;
    }

    editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
  }, [editor]);

  return (
    <div className={theme.MenuBar}>
      <IconButton
        classes={[theme.IconButton]}
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        icon={
          <BoldIcon
            width={24}
            height={24}
            fill={editor.isActive('bold') ? black01 : turquoise01}
          />
        }
      />
      <IconButton
        classes={[theme.IconButton]}
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        icon={
          <ItalicIcon
            width={24}
            height={24}
            fill={editor.isActive('italic') ? black01 : turquoise01}
          />
        }
      />
      <IconButton
        classes={[theme.IconButton]}
        onClick={() => editor.chain().focus().toggleStrike().run()}
        disabled={!editor.can().chain().focus().toggleStrike().run()}
        icon={
          <StrikethroughIcon
            width={24}
            height={24}
            fill={editor.isActive('strike') ? black01 : turquoise01}
          />
        }
      />
      <IconButton
        classes={[theme.IconButton]}
        onClick={setHyperlink}
        icon={
          <LinkIcon
            width={24}
            height={24}
            fill={editor.isActive('link') ? black01 : turquoise01}
          />
        }
      />
      <IconButton
        classes={[theme.IconButton]}
        onClick={() => {
          editor.chain().focus().unsetLink().run();
        }}
        disabled={!editor.isActive('link')}
        icon={
          <LinkSlashIcon
            width={24}
            height={24}
            fill={editor.isActive('link') ? black01 : turquoise01}
          />
        }
      />
      <EllipsisVerticalIcon width={24} height={24} fill={black01} />
      <IconButton
        classes={[theme.IconButton]}
        fill={editor.isActive('paragraph') ? black01 : turquoise01}
        onClick={() => editor.chain().focus().setParagraph().run()}
        icon={
          <ParagraphIcon
            width={24}
            height={24}
            fill={editor.isActive('paragraph') ? black01 : turquoise01}
          />
        }
      />
      <IconButton
        classes={[theme.IconButton]}
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        icon={
          <HeadingIcon
            width={24}
            height={24}
            fill={
              editor.isActive('heading', { level: 1 }) ? black01 : turquoise01
            }
          />
        }
      />
      <IconButton
        classes={[theme.IconButton]}
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        icon={
          <HeadingIcon
            width={22}
            height={22}
            fill={
              editor.isActive('heading', { level: 2 }) ? black01 : turquoise01
            }
          />
        }
      />
      <IconButton
        classes={[theme.IconButton]}
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        icon={
          <HeadingIcon
            width={20}
            height={20}
            fill={
              editor.isActive('heading', { level: 3 }) ? black01 : turquoise01
            }
          />
        }
      />
      <IconButton
        classes={[theme.IconButton]}
        onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
        icon={
          <HeadingIcon
            width={18}
            height={18}
            fill={
              editor.isActive('heading', { level: 4 }) ? black01 : turquoise01
            }
          />
        }
      />
      <IconButton
        classes={[theme.IconButton]}
        fill={editor.isActive('heading', { level: 5 }) ? black01 : turquoise01}
        onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
        icon={
          <HeadingIcon
            width={16}
            height={16}
            fill={
              editor.isActive('heading', { level: 5 }) ? black01 : turquoise01
            }
          />
        }
      />
      <IconButton
        classes={[theme.IconButton]}
        fill={editor.isActive('heading', { level: 6 }) ? black01 : turquoise01}
        onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
        icon={
          <HeadingIcon
            width={14}
            height={14}
            fill={
              editor.isActive('heading', { level: 6 }) ? black01 : turquoise01
            }
          />
        }
      />
      <EllipsisVerticalIcon width={24} height={24} fill={black01} />
      <IconButton
        classes={[theme.IconButton]}
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        icon={
          <BulletListIcon
            width={24}
            height={24}
            fill={editor.isActive('bulletList') ? black01 : turquoise01}
          />
        }
      />
      <IconButton
        classes={[theme.IconButton]}
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        icon={
          <OrderedListIcon
            width={24}
            height={24}
            fill={editor.isActive('orderedList') ? black01 : turquoise01}
          />
        }
      />
      <IconButton
        classes={[theme.IconButton]}
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        icon={
          <QuoteLeftIcon
            width={24}
            height={24}
            fill={editor.isActive('blockquote') ? black01 : turquoise01}
          />
        }
      />
      <EllipsisVerticalIcon width={24} height={24} fill={black01} />
      <IconButton
        classes={[theme.IconButton]}
        onClick={() => editor.chain().focus().undo().run()}
        disabled={!editor.can().chain().focus().undo().run()}
        icon={<RotateLeftIcon width={24} height={24} />}
      />
      <IconButton
        classes={[theme.IconButton]}
        onClick={() => editor.chain().focus().redo().run()}
        disabled={!editor.can().chain().focus().redo().run()}
        icon={<RotateRightIcon width={24} height={24} />}
      />
    </div>
  );
}

MenuBar.propTypes = {
  editor: PropTypes.object,
};
