import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { NavLink, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { useLoggedInUser, useViewPermissions } from 'hooks';
import { clientDashboardTour } from 'config/tour';
import ThreadService from 'services/thread.service';
import {
  isClient,
  isActiveProgram,
  isGracePeriodActive,
  url,
} from 'helpers/user';
import { isMaintenanceDue, isQuitted, isDeclined } from 'helpers/program';
import * as notificationListActions from 'containers/NotificationListWidget/NotificationListWidget.ducks';
import * as inboxActions from 'containers/InboxWidget/InboxWidget.ducks';
import * as userInboxActions from 'containers/InboxWidget/UserInboxWidget/UserInboxWidget.ducks';
import Button from 'components/Button';
import ProgramDocuments from 'components/ProgramDocuments';
import { useBranch } from 'components/BranchProvider';
import logo from 'assets/images/cc_logo.png';

import { InboxItem, NotificationItem } from './components';
import styles from './Navbar.scss';

function Navbar({
  logout,
  inboxListOpen,
  notificationListOpen,
  programDocuments,
  toggleMobileDrawer,
  mobileDrawerOpen,
  toggleInboxList,
  toggleNotificationList,
  createThread,
}) {
  const user = useLoggedInUser();
  const history = useHistory();
  const [branch] = useBranch();
  const { canViewAcneProgramPDFs, canViewNotifications, canViewMessages } =
    useViewPermissions();

  const drawerOpen = notificationListOpen || inboxListOpen;
  function canViewNavigationButtons() {
    /* This code snippet is a function called `canViewNavigationButtons` in the
    Navbar component. */
    if (isEmpty(user.program)) {
      return true;
    }
    return (
      isQuitted(user.program) ||
      isDeclined(user.program) ||
      isActiveProgram(user) ||
      isGracePeriodActive(user)
    );
  }

  const handleInboxClick = async () => {
    if (isClient(user)) {
      const threads = await ThreadService.getByParticipants([user]);
      if (threads.length > 1) {
        toggleInboxList();
        return;
      }
      let thread = threads[0];
      const participants = [url(user.profile.coach)];
      if (!threads.length) {
        thread = await createThread({ participants });
      }
      const messagesPathRegex = /\/messages\/\d+/;
      if (!history.location.pathname.match(messagesPathRegex)) {
        history.push(`/messages/${thread.id}`);
      }
    } else {
      toggleInboxList();
    }
  };

  const renderUserLinks = () => {
    return (
      <div className={styles.navLinkListWrapper}>
        <span>{user.profile.first_name}</span>
        <ul>
          <li>
            <div onClick={logout} className={styles.navLinkListItem}>
              <div className={styles.navLink}>Logout</div>
            </div>
          </li>
        </ul>
      </div>
    );
  };

  const shouldRenderInbox = () =>
    branch.messaging_enabled &&
    (canViewNavigationButtons() ||
      (user.program.maintenance_plan_balance &&
        !isMaintenanceDue(user.program) &&
        canViewMessages));

  const shouldRenderNotifications = () =>
    branch.client_coach_assignment_enabled &&
    (canViewNavigationButtons() ||
      (user.program.maintenance_plan_balance &&
        !isMaintenanceDue(user.program) &&
        canViewNotifications));

  return (
    <div
      className={classNames(styles.root, { [styles.drawerOpen]: drawerOpen })}
    >
      <NavLink to="/" className={styles.logoWrapper}>
        <img className={styles.desktopLogo} src={logo} />
        <img className={styles.mobileLogo} src={logo} />
      </NavLink>
      <div className={styles.navLinksContainer}>
        {!isClient(user) && (
          <>
            <Button
              onClick={() => {
                history.go(0);
              }}
            >
              Refresh
            </Button>
            <NavLink to="/clients" className={styles.navLinkWrapper}>
              <div className={styles.navLink}>Clients</div>
            </NavLink>
          </>
        )}
        <ProgramDocuments />
        {shouldRenderInbox() && (
          <div id={clientDashboardTour.inbox.id}>
            <InboxItem
              onClick={() => {
                handleInboxClick();
              }}
            />
          </div>
        )}
        {shouldRenderNotifications() && (
          <div id={clientDashboardTour.notifications.id}>
            <NotificationItem
              onClick={() => {
                toggleNotificationList();
              }}
            />
          </div>
        )}
        {renderUserLinks()}
        <div onClick={toggleMobileDrawer} className={styles.mobileMenu}>
          <i className={[styles.menuButton, 'material-icons'].join(' ')}>
            {mobileDrawerOpen ? 'clear' : 'menu'}
          </i>
        </div>
      </div>
    </div>
  );
}

Navbar.propTypes = {
  logout: PropTypes.func,
  inboxListOpen: PropTypes.bool.isRequired,
  notificationListOpen: PropTypes.bool.isRequired,
  mobileDrawerOpen: PropTypes.bool,
  toggleMobileDrawer: PropTypes.func,
  toggleInboxList: PropTypes.func.isRequired,
  toggleNotificationList: PropTypes.func.isRequired,
  createThread: PropTypes.func.isRequired,
};

const mapStateToProps = ({ notificationList, inbox }) => ({
  inboxListOpen: inbox.open,
  notificationListOpen: notificationList.open,
});

export default connect(mapStateToProps, {
  toggleNotificationList: notificationListActions.setOpen,
  toggleInboxList: inboxActions.setOpen,
  createThread: userInboxActions.createThread,
})(Navbar);
