/* eslint-disable max-len */

import React from 'react';

const ParagraphIcon = (props) => (
  <svg viewBox="0 0 448 512" {...props}>
    <path d="M192 32l64 0 160 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-32 0 0 352c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-352-32 0 0 352c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-96-32 0c-88.4 0-160-71.6-160-160s71.6-160 160-160z" />
  </svg>
);

ParagraphIcon.defaultProps = {
  height: 13,
  width: 16,
  fill: 'black',
};

export default ParagraphIcon;
