import React from 'react';
import isEmpty from 'lodash/isEmpty';

import {
  canViewMessages,
  canViewNotifications,
  canViewAcneProgramPDFs,
} from 'helpers/maintenancePlanBalance';
import { isActiveProgram, isGracePeriodActive } from 'helpers/user';
import { isQuitted, isDeclined } from 'helpers/program';
import { useLoggedInUser } from 'hooks';

function useViewPermissions() {
  const user = useLoggedInUser();

  const permissions = React.useMemo(
    () => ({
      canViewNavigationButtons:
        isEmpty(user.program) ||
        isQuitted(user.program) ||
        isDeclined(user.program) ||
        isActiveProgram(user) ||
        isGracePeriodActive(user),
      canViewAcneProgramPDFs:
        user.program.maintenance_plan_balance &&
        canViewAcneProgramPDFs(user.program.maintenance_plan_balance),
      canViewNotifications:
        user.program.maintenance_plan_balance &&
        canViewNotifications(user.program.maintenance_plan_balance),
      canViewMessages:
        user.program.maintenance_plan_balance &&
        canViewMessages(user.program.maintenance_plan_balance),
    }),
    [user.program, user.program.maintenance_plan_balance],
  );
  return permissions;
}

export default useViewPermissions;
