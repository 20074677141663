import React from 'react';
import PropTypes from 'prop-types';
import { iconsByPageId } from 'config/surveys';

const SurveyPageIcon = (props) => {
  if (!iconsByPageId.hasOwnProperty(props.page.id)) {
    return null;
  }

  return (
    <div dangerouslySetInnerHTML={{ __html: iconsByPageId[props.page.id] }} />
  );
};

SurveyPageIcon.propTypes = {
  page: PropTypes.object,
};

export default SurveyPageIcon;
