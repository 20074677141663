import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Wysiwyg from 'components/Wysiwyg';

import { CloseIcon } from 'components/Icon';
import { EditIcon } from 'components/Icon';

import StepOptions from './StepOptions';
import theme from './RegimenStep.scss';

const RegimenStep = ({
  index,
  name,
  step,
  limit,
  showStepNumber,
  onChangeStep,
}) => {
  const [editingInstructions, setEditingInstructions] = useState(false);

  const toggleActive = () => {
    onChangeStep({
      stepId: step.id,
      stepBody: {
        product: step.product,
        stepQty: 0,
        includeStep: !step.includeStep,
      },
    });
  };

  const handleSelectChange = (option) => {
    onChangeStep({
      stepId: step.id,
      stepBody: {
        product: step.product,
        stepQty: 0,
      },
    });
    onChangeStep({
      stepId: step.id,
      stepBody: {
        product: option.product.id,
        instructions: option.instructions,
        stepQty: step.stepQty,
      },
    });
  };

  const handleQtyChange = (stepQty) => {
    onChangeStep({
      stepId: step.id,
      stepBody: {
        stepQty,
        product: step.product,
      },
    });
  };

  // herbal regimen uses product name instead of step name
  const herbalRegimen = name.includes('HERBAL');
  const stepName =
    herbalRegimen && step.options.length
      ? step.options[0].product.name
      : step.name;

  return (
    step && (
      <li className={theme.regimenStep}>
        <div>
          <label>
            <input
              type="checkbox"
              className={theme.stepToggle}
              data-testid={`checkbox-${step.id}`}
              onChange={toggleActive}
              checked={step.includeStep}
            />
            <span
              className={classNames({
                [theme.stepName]: true,
                [theme.disabled]: !step.includeStep,
              })}
            >
              {showStepNumber && <span>{`Step ${index + 1}: `}</span>}
              <span>{`${stepName}`}</span>
            </span>
          </label>
          <div style={{ display: step.includeStep ? 'block' : 'none' }}>
            {step.helperText && (
              <div
                className={theme.orderingInstructions}
                dangerouslySetInnerHTML={{ __html: step.helperText }}
              />
            )}
            {step.options?.length > 0 && (
              <StepOptions
                product={step.product}
                options={step.options}
                qty={step.stepQty}
                onChange={handleSelectChange}
                maxQuantity={limit}
                onQuantityChange={handleQtyChange}
              />
            )}
            {step.product !== -1 && (
              <div className={theme.stepInstructions}>
                <div
                  className={classNames(theme.editInstructions, {
                    [theme.previewingInstructions]: !editingInstructions,
                  })}
                  onClick={() => setEditingInstructions(!editingInstructions)}
                >
                  <h4>Instructions</h4>
                  <button>
                    {editingInstructions ? (
                      <CloseIcon
                        onClick={() => setEditingInstructions(false)}
                        width={16}
                        height={16}
                      />
                    ) : (
                      <EditIcon
                        onClick={() => setEditingInstructions(true)}
                        width={16}
                        height={16}
                      />
                    )}
                  </button>
                </div>
                {editingInstructions && (
                  <>
                    <div
                      className={theme.stepInstructionPreview}
                      dangerouslySetInnerHTML={{ __html: step.instructions }}
                    />
                    <Wysiwyg
                      defaultValue={step.instructions}
                      size="small"
                      onChange={(instructions) => {
                        onChangeStep({
                          stepId: step.id,
                          stepBody: {
                            instructions,
                          },
                        });
                      }}
                    />
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </li>
    )
  );
};

RegimenStep.propTypes = {
  index: PropTypes.number,
  step: PropTypes.object.isRequired,
  name: PropTypes.string,
  showStepNumber: PropTypes.bool,
  limit: PropTypes.number,
  onChangeStep: PropTypes.func.isRequired,
};

RegimenStep.defaultProps = {
  showStepNumber: true,
};

export default RegimenStep;
