import React, { Component } from 'react';
import PropTypes from 'prop-types';

import axios from 'config/axios';
import { isRejected } from 'helpers/treatmentPlan';
import { isCoach, isPracticeManager, isClient } from 'helpers/user';

import Rejection from './Rejection';

import theme from './TreatmentPlan.scss';

class TreatmentPlan extends Component {
  state = {
    treatmentPlan: {},
    rejection: {},
  };

  componentDidMount = () => {
    this.getData();
  };

  getData = async () => {
    const { treatmentPlanId } = this.props;

    if (!treatmentPlanId) {
      return;
    }
    const { data: treatmentPlan } = await axios.get(
      `programs/acne/treatment-plans/${treatmentPlanId}/`,
    );
    const rejection = await this.getTreatmentPlanRejections(treatmentPlan);

    this.setState({
      treatmentPlan,
      rejection,
    });
  };

  getTreatmentPlanRejections = async (treatmentPlan) => {
    if (treatmentPlan.rejection) {
      const { data: rejection } = await axios.get(treatmentPlan.rejection);
      return rejection;
    }
    return {};
  };

  render() {
    if (!this.props.treatmentPlanId) {
      return <h1>{"You don't have a treatment plan yet"}</h1>;
    }

    return (
      <React.Fragment>
        <header
          className={
            isCoach(this.props.user) || isPracticeManager(this.props.user)
              ? theme.headerCoach
              : theme.header
          }
        >
          <div>
            {isClient(this.props.user)
              ? 'YOUR TREATMENT PLAN'
              : 'TREATMENT PLAN'}
          </div>
        </header>
        <div className={theme.body}>
          <div>
            <div
              dangerouslySetInnerHTML={{
                __html: this.state.treatmentPlan.body,
              }}
            />
            {(isCoach(this.props.user) || isPracticeManager(this.props.user)) &&
              isRejected(this.state.treatmentPlan) && (
                <Rejection
                  classes={[theme.Rejection]}
                  rejection={this.state.rejection}
                />
              )}
            {isClient(this.props.user) && (
              <>
                <header className={theme.documentsHeader}>
                  <div>ACNE PROGRAM PDFS</div>
                </header>
                <ul className={theme.documentLinks}>
                  {this.props.programDocuments.map((document, i) => (
                    <li key={i}>
                      <a href={document.upload} target="_blank">
                        {document.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

TreatmentPlan.propTypes = {
  user: PropTypes.object,
  treatmentPlanId: PropTypes.string,
  programDocuments: PropTypes.array,
};

export default TreatmentPlan;
