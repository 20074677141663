/* eslint-disable max-len */

import React from 'react';

const EllipsisVerticalIcon = (props) => (
  <svg viewBox="0 0 128 512" {...props}>
    <path d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z" />
  </svg>
);

EllipsisVerticalIcon.defaultProps = {
  height: 13,
  width: 16,
  fill: 'black',
};

export default EllipsisVerticalIcon;
