import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { coachDashboardTour, clientDashboardTour } from 'config/tour';
import { isClient } from 'helpers/user';
import { isMaintenanceDue } from 'helpers/program';
import { useLoggedInUser, useViewPermissions } from 'hooks';
import MenuItem from 'components/Navbar/components/MenuItem';
import { DocumentIcon } from 'components/Icon';

import styles from './ProgramDocuments.scss';

function ProgramDocuments({ className }) {
  const user = useLoggedInUser();
  const { canViewAcneProgramPDFs, canViewNavigationButtons } =
    useViewPermissions();

  const programDocuments = useSelector(({ app }) => app.programDocuments);

  if (isClient(user)) {
    return (
      <>
        {(canViewNavigationButtons ||
          (user.program.maintenance_plan_balance &&
            !isMaintenanceDue(user.program) &&
            canViewAcneProgramPDFs)) && (
          <div
            id={clientDashboardTour.documents.id}
            className={classNames(styles.DocumentList, className)}
          >
            <span>Acne Program PDFs</span>
            <ul>
              {programDocuments.map((document, i) => (
                <li key={i} onClick={() => window.open(document.upload)}>
                  <div>
                    <div>{document.name}</div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </>
    );
  }

  return (
    <div id={coachDashboardTour.documents.id}>
      <MenuItem link="/documents">
        <DocumentIcon />
      </MenuItem>
    </div>
  );
}

ProgramDocuments.propTypes = {
  className: PropTypes.string,
};

export default ProgramDocuments;
