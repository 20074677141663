/* eslint-disable max-len */

import React from 'react';

const ItalicIcon = (props) => (
  <svg viewBox="0 0 384 512" {...props}>
    <path d="M128 64c0-17.7 14.3-32 32-32l192 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-58.7 0L160 416l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 480c-17.7 0-32-14.3-32-32s14.3-32 32-32l58.7 0L224 96l-64 0c-17.7 0-32-14.3-32-32z" />
  </svg>
);

ItalicIcon.defaultProps = {
  height: 13,
  width: 16,
  fill: 'black',
};

export default ItalicIcon;
