import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { isActiveProgram, isGracePeriodActive } from 'helpers/user';
import {
  canViewMessages,
  canViewNotifications,
} from 'helpers/maintenancePlanBalance';
import { userAware } from 'components/UserProvider';
import ProgramDocuments from 'components/ProgramDocuments';
import * as notificationListActions from 'containers/NotificationListWidget/NotificationListWidget.ducks';
import * as inboxActions from 'containers/InboxWidget/InboxWidget.ducks';

import styles from './MobileDrawer.scss';

const MobileDrawer = ({
  user,
  open,
  toggle,
  logout,
  openInboxList,
  openNotificationList,
}) => {
  const notifications = () => {
    toggle();
    setTimeout(() => {
      openNotificationList();
    }, 500);
  };
  const messages = () => {
    toggle();
    setTimeout(() => {
      openInboxList();
    }, 500);
  };
  const logoutUser = () => {
    toggle();
    logout();
  };

  const shouldRenderInbox = () =>
    isActiveProgram(user) ||
    isGracePeriodActive(user) ||
    (user.program.maintenance_plan_balance &&
      canViewMessages(user.program.maintenance_plan_balance));

  const shouldRenderNotifications = () =>
    isActiveProgram(user) ||
    isGracePeriodActive(user) ||
    (user.program.maintenance_plan_balance &&
      canViewNotifications(user.program.maintenance_plan_balance));

  return (
    <div
      className={`${styles.container} ${open ? styles.open : styles.closed}`}
    >
      <div
        className={open ? styles.root : [styles.root, styles.closed].join(' ')}
      >
        <div className={styles.backdrop} />
        <div className={styles.drawer}>
          <ul className={styles.drawerLinks}>
            {shouldRenderNotifications && (
              <li onClick={notifications} className={styles.drawerLink}>
                Notifications
              </li>
            )}
            {shouldRenderInbox() && (
              <li onClick={messages} className={styles.drawerLink}>
                Messages
              </li>
            )}
            <li>
              <ProgramDocuments className={styles.ProgramDocuments} />
            </li>
            <li onClick={logoutUser} className={styles.drawerLink}>
              Logout
            </li>
          </ul>
          <div className={styles.logoWrapper}>
            <div className={styles.drawerLogo} />
          </div>
        </div>
      </div>
    </div>
  );
};

MobileDrawer.propTypes = {
  user: PropTypes.object.isRequired,
  logout: PropTypes.func,
  open: PropTypes.bool,
  toggle: PropTypes.func,
  openInboxList: PropTypes.func.isRequired,
  openNotificationList: PropTypes.func.isRequired,
};

export default connect(null, {
  openNotificationList: notificationListActions.setOpen,
  openInboxList: inboxActions.setOpen,
})(userAware(MobileDrawer));
