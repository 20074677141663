import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ToastContainer } from 'react-toastify';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import ReactPixel from 'react-facebook-pixel';
import * as Sentry from '@sentry/react';
import { maintenanceMode } from 'config/config';

// TODO: Remove condition and add this loaders to webpack config
if (process.env.NODE_ENV !== 'test') {
  // eslint-disable-next-line global-require
  require('!style-loader!css-loader!normalize.css/normalize.css');
  // eslint-disable-next-line global-require
  require('!style-loader!css-loader!react-toastify/dist/ReactToastify.min.css');
  // eslint-disable-next-line global-require
  require('!style-loader!css-loader!react-phone-number-input/style.css');
  // eslint-disable-next-line global-require
  require('!style-loader!css-loader!react-image-gallery/styles/css/image-gallery.css');
}

import { facebookPixelId } from 'config/config';
import BranchService from 'services/branch.service';
import UserProvider from 'components/UserProvider';
import { useBranch } from 'components/BranchProvider';
import Navbar from 'components/Navbar';
import MobileDrawer from 'components/MobileDrawer';
import NotificationListWidget from 'containers/NotificationListWidget';
import InboxWidget from 'containers/InboxWidget';
import Loader from 'components/Loader';
import {
  white01,
  lightTurquoise,
  orange01,
  turquoise01,
  bluePrimaryDark,
} from 'styles/colors.sss';
import 'styles/main.scss';

import * as actions from './App.ducks';
import { MaintenanceDueModal } from './components';

const MAINTENANCE_DUE_MODAL_ID = 'Conversation/MAINTENANCE_DUE_MODAL_ID';

const theme = createTheme({
  palette: {
    primary: {
      light: lightTurquoise,
      main: turquoise01,
      dark: bluePrimaryDark,
      contrastText: white01,
    },
    secondary: {
      light: orange01,
      main: orange01,
      dark: orange01,
      contrastText: white01,
    },
  },
  overrides: {
    MuiMenuItem: {
      'root': {
        '&$selected': {
          backgroundColor: turquoise01,
        },
        '&$selected:hover': {
          backgroundColor: turquoise01,
        },
      },
    },
  },
});

// TODO: Move this to App.sss file
const styles = {
  noScroll: {
    overflow: 'hidden',
    position: 'fixed',
    top: 64,
    right: 0,
    left: 0,
    bottom: 0,
  },
  drawerOpen: {
    marginTop: 64,
  },
};

function App({
  children,
  isLoggedIn,
  appIsInitialized,
  user,
  logout,
  inboxListOpen,
  notificationListOpen,
  initialize,
}) {
  const [mobileDrawerOpen, setMobileDrawerOpen] = React.useState(false);
  const [branch, setBranch] = useBranch();

  React.useEffect(() => {
    const bootstrapApp = (id) => {
      const branchId = Number(id);
      return BranchService.get(branchId).then(setBranch);
    };
    ReactPixel.init(facebookPixelId);
    initialize({ bootstrapApp });
  }, [initialize, setBranch]);

  function toggleMobileDrawer(open) {
    let newValue = open;
    if (typeof open !== 'boolean') {
      newValue = !mobileDrawerOpen;
    }
    setMobileDrawerOpen(newValue);
  }

  if (maintenanceMode) {
    return <h1>App in maintenance, please come back later...</h1>;
  }

  if (!appIsInitialized) {
    return (
      <Loader loading type="fixed" size="large">
        Clear Connection by Natural Acne Clinic
      </Loader>
    );
  }

  const drawerOpen = notificationListOpen || inboxListOpen;
  let containerStyles = {};

  if (mobileDrawerOpen) {
    containerStyles = {
      ...containerStyles,
      ...styles.noScroll,
    };
  }

  if (drawerOpen) {
    containerStyles = {
      ...containerStyles,
      ...styles.drawerOpen,
    };
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <UserProvider user={user}>
        <ThemeProvider theme={theme}>
          <>
            {isLoggedIn && (
              <>
                <Navbar
                  logout={logout}
                  mobileDrawerOpen={mobileDrawerOpen}
                  toggleMobileDrawer={toggleMobileDrawer} // eslint-disable-line react/jsx-no-bind
                />
                {branch.client_coach_assignment_enabled && (
                  <NotificationListWidget />
                )}
                {branch.messaging_enabled && <InboxWidget />}
                <MaintenanceDueModal modalId={MAINTENANCE_DUE_MODAL_ID} />
              </>
            )}
            <MobileDrawer
              toggle={toggleMobileDrawer} // eslint-disable-line react/jsx-no-bind
              logout={logout}
              open={mobileDrawerOpen}
            />
            <ToastContainer autoClose={3000} pauseOnFocusLoss={false} />
            <div style={containerStyles}>{children}</div>
            <ReactQueryDevtools initialIsOpen={false} />
          </>
        </ThemeProvider>
      </UserProvider>
    </MuiPickersUtilsProvider>
  );
}

App.propTypes = {
  children: PropTypes.node.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  appIsInitialized: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  inboxListOpen: PropTypes.bool.isRequired,
  notificationListOpen: PropTypes.bool.isRequired,
  initialize: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = ({ app, user, inbox, notificationList }) => ({
  ...app,
  user,
  inboxListOpen: inbox.open,
  notificationListOpen: notificationList.open,
});

export default Sentry.withProfiler(connect(mapStateToProps, actions)(App));
